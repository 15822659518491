.legalText  {
  line-height: 1.15;

  h1 {
    color: $primary;
    text-align: center;
    font-weight: bold;
    margin-bottom: 70px;
  }
  h2 {
    margin-top: 50px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  a {
    color: $primary;
    text-decoration: none;
  }
  li {
    margin-bottom: 10px;
  }
  ul, ol {
    margin-bottom: 20px;
  }
  table {
    margin: 30px 0;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: $primary;
    font-weight: bold;
    color: white;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  tr:nth-child(even){background-color: #f2f2f2;}
}