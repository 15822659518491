//body {
//  @extend .ff-nunito;
//}
//

.ff-nunito {
  font-family: 'Nunito', sans-serif;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}



// -- Text sizes --
html {
  font-size: 14px;
  @include media-breakpoint-up("md") {
    font-size: 16px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 18px;
  }
}

.mainTitle {
  font-size: 30px;
  line-height: 1.5;
  //font-weight: 500;

  @include media-breakpoint-up("sm") {
    font-size: 35px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 42px;
  }
  @include media-breakpoint-up("xxl") {
    font-size: 50px;
  }
}

.title {
  font-size: 26px;
  line-height: 1.3;
  //font-weight: bold;

  @include media-breakpoint-up("lg") {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 22px;
  line-height: 1.3;

  @include media-breakpoint-up("lg") {
    font-size: 26px;
  }
}

section, .section {
  width: 100%;
  padding: 17vh 5.5%;

  @include media-breakpoint-up("md") {
    padding: 7% 5.5%;
  }
}
.section-content {
  max-width: 1300px;
  @extend .mx-auto;
}


.btn {
  border: 0.1px $white solid;
  font-weight: bold;
}
.btn-primary, .btn-primary:hover {
  color: $white;
}
.rounded {
  border-radius: 1rem !important;
}

.link:hover{
  color: $primary;
}


.thumbnail {
  -webkit-box-shadow: 10px 10px 35px 3px rgba(0,0,0,0.5); 
  box-shadow: 10px 10px 35px 3px rgba(0,0,0,0.5);
}




//@function hexToRGB($hex) {
//  @return red($hex), green($hex), blue($hex);
//}
////rgba(var(#{hexToRGB($secondary)}), 0)
//
//$secondary-65: rgba($secondary, 0.65);
//.bg-secondary-65 {
//  background-color: $secondary-65;
//}
//
//.bg-g-secondary {
//  background: linear-gradient(0deg, $secondary-65 75%, rgba(0,0,0,0) 100%);
//}
//.bg-g-secondary-reversed {
//  background: linear-gradient(0deg, rgba(0,0,0,0) 10%, $secondary-65 100%);
//}
//.bg-g-secondary-responsive {
//  background: linear-gradient(0deg, rgba(0,0,0,0) 10%, $secondary-65 100%);
//  @include media-breakpoint-up("md") {
//    background: linear-gradient(0deg, $secondary-65 75%, rgba(0,0,0,0) 100%);
//  }
//}
//.bg-g-light-secondary {
//  background: linear-gradient(0deg, $secondary 50%, $light 100%);
//}
